import { render, staticRenderFns } from "./settingLock.vue?vue&type=template&id=408166d2&scoped=true&"
import script from "./settingLock.vue?vue&type=script&lang=js&"
export * from "./settingLock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "408166d2",
  null
  
)

export default component.exports