import API from "@/httpsAPI/API";
import request from "@/utils/request";

// 项目信息查询
export function detailProject(params) {
    return request({
      url: '/project/setting/detail/' + params,
      method:'post',
      data:{}
    })
  }

// 锁定项目
export function lockProject(params) {
    return request({
      url: "/project/setting/lock",
      method:'post',
      data:params
    })
  }
// 取消解锁项目
export function cancelLockProject(params) {
  return request({
    url: '/project/setting/cancel-lock',
    method:'post',
    data:params
  })
}
// 复制项目
export function duplicateProject(params) {
    return request({
      url: '/project/setting/duplicate',
      method:'post',
      data:params
    })
  }

// 归档项目
export function archiveProject(params) {
    return request({
      url: '/project/setting/archive/' + params,
      method:'post',
      data:{}
    })
  }
// 取消项目
export function unArchiveProject(params) {
  return request({
    url: '/project/setting/unArchive/' + params,
    method:'post',
    data:{}
  })
}

// 删除项目
export function deleteProject(params) {
    return request({
      url: 'project/setting/delete/' + params.id,
      method:'post',
      data:params
    })
  }

// 配置中心删除项目
export function delProject(params) {
  return request({
    url: 'project/delete/' + params,
    method:'get',
    data:{}
  })
}

// 验证锁定项目的解锁密码
export function unlockPwdProject(params) {
  return request({
    url: 'project/setting/unlockPwdProject',
    method:'post',
    data:params
  })
}

// 查询用户对锁定项目是否有访问权限
export function unlockProjectState(params) {
  return request({
    url: 'project/setting/unlockProjectState/' + params.id,
    method:'post',
    data:{}
  })
}
